core.component('coreDialogComponent', {
    templateUrl: 'components/dialog/dialog.html',
    controller: ['$rootScope', function($rootScope){

        var $ctrl = this;

        $ctrl.dialogIcons = {
            success: '<span class="fs-5tx material-symbols-outlined text-success">check_circle</span>',
            info: '<span class="fs-5tx material-symbols-outlined text-warning">info</span>',
            fail: '<span class="fs-5tx material-symbols-outlined text-danger">cancel</span>'
        };

        $ctrl.toggleLoading = (dialog) => {
            dialog.isLoading = !dialog.isLoading;
        }

        $rootScope.$watch('dialogs', (newVal, oldVal)=>{ $ctrl.dialogs = newVal; });
    }],
});

core.factory('coreDialog', ["$rootScope", "$q", function($rootScope, $q) {

    const coreDialog = {};
    $rootScope.dialogs = [];

    coreDialog.show=(title = 'Confirmar Ação', message = 'Tem certeza que deseja prosseguir?', accept = 'Continuar', dismiss = 'Cancelar', icon = null, callback = null)=>{

        let index = $rootScope.dialogs.length;
        return $q((resolve, reject) => {
            const dialog = {
                resolve, reject, title, message, accept, dismiss, icon
            }

            $rootScope.dialogs.push(dialog);
        }).then(() => {
            if (callback) {
                return callback()
            }
        }).finally(() => {
            $rootScope.dialogs.splice(index, 1);
        })
    };

    return coreDialog;
}]);