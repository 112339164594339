crmw.component('modalMsgComponent', {
    templateUrl: 'components/modalMsg/modalMsg.html',
    controller: 'modalMsgComponentController',
    controllerAs: '$ctrl',
    bindings:{ contatos: '<', selecionados:'<', etapas: '<', showModal:'='}
});

crmw.controller('modalMsgComponentController', ['$state', 'coreLanguage', 'coreFb', '$scope', function($state, $text, $fb, $scope) {
    const $ctrl = this;

    $ctrl.destinatarios=[];
    $ctrl.mensagem = {};

    $ctrl.closeModal=()=>{
        $ctrl.showModal=false;
    };

    $ctrl.parseMsg=(texto, contato)=>{
        let retorno = texto;

        let data = new Date();
        let hora = data.getHours();
        let saudacao = 4 < hora && hora < 12 ? "Bom dia" : 12 <= hora && hora < 19 ? "Boa Tarde" : "Boa noite";

        let pNome = contato.nome.split(' ');

        retorno = retorno.replaceAll(':nome', pNome[0]);
        retorno = retorno.replaceAll(':saudacao', saudacao);

        return retorno;
    };

    $ctrl.sendMsgs=async()=>{
        $ctrl.loading = true;

        let userDoc = await $fb.db.get(`usuarios/${$fb.auth.getIdToken().claims.idUsuario}`);

        if(!userDoc.data.chavesApi||!userDoc.data.chavesApi.length){
            alert('Você não possui whatsapp conectado.');
            $ctrl.closeModal();
            return;
        }
        
        for(let contato of $ctrl.destinatarios){

            if(!contato.whatsapp){
                continue;
            };

            let msgObj = {
                destino:contato.whatsapp,
                idExterno: false,
                origem: `${userDoc.data.chavesApi[0]}-${userDoc.id}`,
                texto: $ctrl.parseMsg($ctrl.mensagem.texto, contato),
                tipo:"message"
            };

            try{ await $fb.db.add('mensagens_programadas', msgObj); }
            catch(e){ console.log(e); }

            if(!!$ctrl.mensagem.etapa){
                let obj = {};
                obj[`admin.etapa`] = $ctrl.mensagem.etapa;
                await $fb.db.update(`crmw_contatos/${contato.id}`, obj);
            }

            if(!!$ctrl.mensagem.observacao){
                let now = new Date();

                if(!contato.admin.observacoes){ contato.admin.observacoes = []; }
                contato.admin.observacoes.push({data:now.getTime(), text:$ctrl.mensagem.observacao});

                let obj = {};
                obj[`admin.observacoes`] = contato.admin.observacoes;
                await $fb.db.update(`crmw_contatos/${contato.id}`, obj);
            }
        };

        $scope.$apply(()=>{
            $ctrl.loading = false;
            $ctrl.closeModal();
        });

    };

    $ctrl.$onInit=()=>{

        if($ctrl.contatos.length){
            $ctrl.contatos.forEach(item => {
                if($ctrl.selecionados[item.id]){ $ctrl.destinatarios.push(item); }
            });
        };

        if($ctrl.destinatarios.length>100){
            alert("Você não pode enviar mensagem para mais de 100 contatos simultâneamente.");
            $ctrl.closeModal();
        }

    };

}]);