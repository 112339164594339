/* navigator.serviceWorker.register('service-worker.js').then(function(reg){
    console.log("SW registration succeeded.");
}); */

core.config(['$urlServiceProvider', function ($urlServiceProvider) {
    $urlServiceProvider.config.strictMode(false)
}])

core.run(['$rootScope', '$state', '$trace', '$transitions', 'coreDialog', 'coreFb', function($rootScope, $state, $trace, $transitions, $dialog, $fb) {

    $rootScope.stateParams = $state.params;

    const checkSalvarPendente = (event) => {
        if (!$state.params.salvarPendente) {
            return
        }

        if ($state.params.salvarPendente.call && !$state.params.salvarPendente()) {
            return
        }

        if (event.type == 'beforeunload') {
            event.preventDefault();
            event.returnValue = true;

            return true
        }

        return $dialog.show('Salvamento Pendente', 'As alterações não foram salvas e serão perdidas, deseja continuar?').catch(() => {
            return false
        });
    }

    const checkShowGlobalLoading = (transition) => {
        const isSameState = transition.from() == transition.to();
        const isRedirect = transition.redirectedFrom() !== null;
        const transitionParam = transition.params().showGlobalLoading;
        const toStateParam = transition.to().params?.showGlobalLoading;
        const inheritedParam = transitionParam !== undefined && toStateParam == undefined;

        if (transitionParam == true && !isRedirect && (isSameState || inheritedParam)) {
            return transition.targetState().withParams({ showGlobalLoading: false });
        }
    }

    const checkAuthentication = (transition) => {
        if (APP_CONSTANTS.APP_CONFIG.authCheck == false) {
            return
        }

        const requiresAuth = transition.to().data?.requiresAuth == true;
        if (requiresAuth) {
            $state.params.showGlobalLoading = true;
        }

        return $fb.auth.waitUser().then((user) => {
            if (!requiresAuth) {
                return
            }

            $state.params.showGlobalLoading = false;

            return user ? true : $state.target('login', undefined, { location: true });
        })
    }

    const savePreviousState = () => {
        $rootScope.transitionFrom = $state.target($state.current, $state.params);
    }

    $transitions.onStart({}, checkShowGlobalLoading)
    $transitions.onStart({}, checkSalvarPendente)
    $transitions.onStart({}, checkAuthentication)
    $transitions.onStart({}, savePreviousState)

    window.onbeforeunload = checkSalvarPendente;

    //$trace.enable(1);
}])