const auth = angular.module('auth', []);

auth.config(['$stateProvider', function($stateProvider) {

    const routes = [
        {
            name: 'login',
            url: '/login',
            component: 'auth.loginComponent',
            resolve: {
                returnTo: ['$transition$', function ($transition$) {
                    if (!$transition$.redirectedFrom()) {
                        return
                    }

                    return $transition$.redirectedFrom().targetState();
                }]
            }
        },
        {
            name: 'register',
            url: '/cadastro',
            component: 'auth.registerComponent'
        },
        {
            name: 'recover',
            url: '/recuperar',
            component: 'auth.recoverComponent'
        },
        {
            name: 'resend',
            url: '/reenvio',
            component: 'auth.resendComponent'
        },
        {
            name: 'loginWithLink',
            url: '/loginWithLink',
            component: 'auth.loginWithLinkComponent'
        }
    ];

    routes.forEach($stateProvider.state);
}]);