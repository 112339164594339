core.component('coreChatMensagemComponent', {
    templateUrl: 'components/chat/mensagem.html',
    controller: 'core.chatMensagemController',
    bindings: { chatPath: '<', mensagemData: '<', showInfo: '<', showOptions: '<', onUsuarioSelect: '<' }
});

core.controller('core.chatMensagemController', ['coreDialog', 'coreToast', 'coreFb', function($dialog, $toast, $fb) {

    const $ctrl = this;

    $ctrl.usuarioId = null;

    $ctrl.isSender = () => {
        return $ctrl.mensagemData.data.criacao.idUsuario === $ctrl.usuarioId || ($ctrl.mensagemData.data.criacao.idUsuario === undefined && $ctrl.showInfo)
    }

    $ctrl.toggleMensagem = () => {

        const callback = () => {
            const mensagemData = { arquivado: !$ctrl.mensagemData.data.arquivado };
            return $fb.rt.update(`${$ctrl.chatPath}/${$ctrl.mensagemData.id}`, mensagemData);
        }

        if ($ctrl.mensagemData.data.arquivado) {
            $dialog.show(undefined, undefined, undefined, undefined, undefined, callback).catch(() => {
                // reject
            });

            return
        }

        callback();
    }

    $ctrl.toggleFixado = () => {

        const callback = () => {
            const mensagemData = { fixado: true };
            return $fb.rt.update(`${$ctrl.chatPath}/${$ctrl.mensagemData.id}`, mensagemData);
        }

        $dialog.show(undefined, undefined, undefined, undefined, undefined, callback).catch(() => {
            // reject
        });
    }

    $ctrl.showAccordions = {};

    $ctrl.toggleAccordion = (accordionIndex) => {

        $ctrl.showAccordions[accordionIndex] = !$ctrl.showAccordions[accordionIndex];
    }

    $ctrl.submitEvento = (nomeEvento) => {

        const messageData = {
            tipo: 'Evento',
            msg: nomeEvento
        };

        $fb.rt.add($ctrl.chatPath, messageData).then(() => {

        })
    }

    $ctrl.$onInit = () => {

        $ctrl.usuarioId = $fb.auth.getIdToken()?.claims.idUsuario ?? false;
    }
}])