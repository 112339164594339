core.component('coreSliderComponent', {
    templateUrl: 'components/slider/slider.html',
    controller: 'core.sliderontroller',
    transclude: true,
    bindings: { options: '<' }
});

core.controller('core.sliderontroller', ['$scope', '$element', '$transclude', '$timeout', function($scope, $element, $transclude, $timeout) {

    const $ctrl = this;

    $ctrl.sliderElement = null;

    $ctrl.$onInit = () => {

        $ctrl.sliderElement = $element.children()[0];

        angular.element($ctrl.sliderElement).addClass('swiper');
        angular.element($ctrl.sliderElement.querySelector('.swiper-wrapper')).children().addClass('swiper-slide');

        $scope.$applyAsync(() => {
            let wrapperElem = angular.element($ctrl.sliderElement.querySelector('.swiper-wrapper'))[0];
            angular.element(wrapperElem).children().addClass('swiper-slide');

            new Swiper($ctrl.sliderElement, {
                navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },

                ...$ctrl.options ?? {},
            });
        })
    }
}])