core.factory('coreUtils', ['$q', '$http', function($q, $http) {
    const utils = {};

    utils.httpReq = (method, url, config) => {
      const httpCall = $http[method];
      return httpCall(url, config);
    }

    utils._calc_digitos_posicoes = (digitos, posicoes, soma_digitos) => {
        // Garante que o valor é uma string
        digitos = digitos.toString();

        // Faz a soma dos dígitos com a posição
        // Ex. para 10 posições:
        //   0    2    5    4    6    2    8    8   4
        // x10   x9   x8   x7   x6   x5   x4   x3  x2
        //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
        for (var i = 0; i < digitos.length; i++) {
            // Preenche a soma com o dígito vezes a posição
            soma_digitos = soma_digitos + (digitos[i] * posicoes);

            // Subtrai 1 da posição
            posicoes--;

            // Parte específica para CNPJ
            // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
            if (posicoes < 2) {
                // Retorno a posição para 9
                posicoes = 9;
            }
        }

        // Captura o resto da divisão entre soma_digitos dividido por 11
        // Ex.: 196 % 11 = 9
        soma_digitos = soma_digitos % 11;

        // Verifica se soma_digitos é menor que 2
        if (soma_digitos < 2) {
            // soma_digitos agora será zero
            soma_digitos = 0;
        } else {
            // Se for maior que 2, o resultado é 11 menos soma_digitos
            // Ex.: 11 - 9 = 2
            // Nosso dígito procurado é 2
            soma_digitos = 11 - soma_digitos;
        }

        // Concatena mais um dígito aos primeiro nove dígitos
        // Ex.: 025462884 + 2 = 0254628842
        var cpf = digitos + soma_digitos;

        // Retorna
        return cpf;

    } // calc_digitos_posicoes

    utils.validaCpf = (valor) => {
        // Garante que o valor é uma string
        valor = valor.toString();

        // Remove caracteres inválidos do valor
        valor = valor.replace(/[^0-9]/g, '');


        // Captura os 9 primeiros dígitos do CPF
        // Ex.: 02546288423 = 025462884
        var digitos = valor.substr(0, 9);

        // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
        var novo_cpf = utils._calc_digitos_posicoes(digitos, 10, 0);

        // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
        var novo_cpf = utils._calc_digitos_posicoes(novo_cpf, 11, 0);

        // Verifica se o novo CPF gerado é idêntico ao CPF enviado
        if (novo_cpf === valor) {
            // CPF válido
            return true;
        } else {
            // CPF inválido
            return false;
        }
    }

    utils.randomId = (length = 20) => {
        const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        let autoId = ''

        for (let i = 0; i < length; i++) {
          autoId += CHARS.charAt(
            Math.floor(Math.random() * CHARS.length)
          )
        }
        return autoId
    }

    utils.downloadBlob = (blob, name) => {
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
    }

    utils.copyText = (text) => {
      const input = document.createElement("input");
      input.value = text;

      input.select();
      input.setSelectionRange(0, 99999);

      return $q.when(navigator.clipboard.writeText(input.value));
    }

    utils.unflatObject = (data) => {
      const nestedObject = {};

      for (const name in data) {
          let pathStep = nestedObject;
          const segs = name.split('.');
          while (segs.length > 0) {
              const step = segs.shift();

              if (!pathStep[step]) {
                  pathStep[step] = segs.length === 0 ? data[name] : {};
              }

              pathStep = pathStep[step];
          }
      }

      return nestedObject
    }

    utils.getNestedField = (data, name) => {
      let pathStep = data;

      if (data[name]) {
        return data[name]
      }

      const segs = name.split('.');
      while (segs.length > 0) {
        const step = segs.shift();

        if (pathStep[step] == undefined) {
          return
        }

        if (segs.length === 0 ) {
          return pathStep[step];
        }

        pathStep = pathStep[step];
      }
    }

    utils.localStorageAvailable=()=>{
      //Login com link utiliza o localstorage para tornar o fluxo 
      var test = 'test';
      try {
          localStorage.setItem(test, test);
          localStorage.removeItem(test);
          return true;
          
      } catch(e) {
          return false;
      }
    };

    return utils;
}])

core.directive("coreFilesInput", function() {
  return {
    require: "ngModel",
    link: function postLink(scope,elem,attrs,ngModel) {
      elem.on("change", function(e) {
        var files = elem[0].files;
        ngModel.$setViewValue(files);
      })
    }
  }
})

core.directive("coreIntInput", function () {
  return {
    require: 'ngModel',
    link: function (scope, elem, attrs, ngModel) {
      elem.attr('maxlength', attrs.format ? 21 : 14);
      elem.addClass('text-end');

      const setFormattedValue = (value) => {
        if (!value) {
          return [null, null];
        }

        let numberValue = value;
        if (typeof numberValue !== 'number') {
          const cleanValue = '0' + numberValue.toString().replace(/\D/g, '');

          numberValue = Number(cleanValue);
        }

        if (numberValue > attrs.ngMax) {
          numberValue = attrs.ngMax;
        }

        let formattedValue = numberValue.toString();
        if (attrs.format) {
          formattedValue = numberValue.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
        }

        const getCursorPosition = () => {
          if (numberValue < 1) {
            return 1000
          }

          const selectionStart = elem[0].selectionStart;
          const preLength = scope.currentValue?.length ?? 0;
          const posLength = formattedValue.length;

          let ajuste = 0;

          if (preLength > posLength) {
            ajuste = 1;
          }

          if (preLength < posLength) {
            ajuste = -1;
          }

          const selectionToEnd = preLength - (selectionStart + ajuste);
          const selectionNewPos = posLength - selectionToEnd;

          if (selectionNewPos < 0) {
            return 0;
          }

          return selectionNewPos;
        }

        if (value && value.toString() !== formattedValue) {
          const newCursorPosition = getCursorPosition();

          ngModel.$viewValue = formattedValue;
          ngModel.$render();

          elem[0].selectionEnd = newCursorPosition;
          elem[0].selectionStart = newCursorPosition;
        }

        scope.currentValue = formattedValue;

        return [ numberValue, formattedValue ]
      }

      ngModel.$formatters.push(function (value) {
        const [ numberValue, formattedValue ] = setFormattedValue(value);

        return formattedValue;
      });


      ngModel.$parsers.push(function (value) {
        const [ numberValue, formattedValue ] = setFormattedValue(value);

        return numberValue;
      });
    }
  };
})

core.directive("coreFloatInput", function () {
  return {
    require: 'ngModel',
    link: function (scope, elem, attrs, ngModel) {
      elem.attr('maxlength', 20);
      elem.addClass('text-end');

      const setFormattedValue = (value) => {
        if (!value && ngModel.$pristine) {
          return [null, null];
        }

        let numberValue = value ?? 0;
        if (typeof numberValue !== 'number') {
          const cleanValue = '0' + numberValue.toString().replace(/\D/g, '');
          const splitValue  = cleanValue.slice(0, cleanValue.length - 2) + '.' + cleanValue.slice(cleanValue.length - 2);

          numberValue = Number(splitValue);
        }

        if (numberValue > attrs.ngMax) {
          numberValue = attrs.ngMax;
        }

        if (numberValue < attrs.ngMin) {
          numberValue = attrs.ngMin;
        }

        const formattedValue = numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        const getCursorPosition = () => {
          if (numberValue < 1) {
            return 1000
          }

          const selectionStart = elem[0].selectionStart;
          const preLength = scope.currentValue?.length ?? 0;
          const posLength = formattedValue.length;

          let ajuste = 0;

          if (preLength > posLength) {
            ajuste = 1;
          }

          if (preLength < posLength) {
            ajuste = -1;
          }

          const selectionToEnd = preLength - (selectionStart + ajuste);
          const selectionNewPos = posLength - selectionToEnd;

          if (selectionNewPos < 0) {
            return 0;
          }

          return selectionNewPos;
        }

        if (value && value.toString() !== formattedValue) {
          const newCursorPosition = getCursorPosition();

          ngModel.$viewValue = formattedValue;
          ngModel.$render();

          elem[0].selectionEnd = newCursorPosition;
          elem[0].selectionStart = newCursorPosition;
        }

        scope.currentValue = formattedValue;

        return [ numberValue, formattedValue ]
      }

      ngModel.$formatters.push(function (value) {
        const [ numberValue, formattedValue ] = setFormattedValue(value);

        return formattedValue;
      });


      ngModel.$parsers.push(function (value) {
        const [ numberValue, formattedValue ] = setFormattedValue(value);

        return numberValue;
      });
    }
  };
})

core.directive("coreSortHeader", function() {
  return {
    scope: { sortBy: '=' },
    link: function postLink(scope,elem,attrs) {
      const headChildren = angular.element(elem.children()[0]).children();

      Object.values(headChildren).forEach(el => {
        if (!el.nodeType) {
          return
        }

        const ngElement = angular.element(el);

        const field = ngElement.attr('field');
        if (!field) {
            return
        }

        ngElement.addClass('user-select-none cursor-pointer');

        ngElement.on('click', () => {
          scope.$applyAsync(() => {
            headChildren.removeClass('asc desc');

            if (scope.sortBy.field == field && scope.sortBy.desc) {
              scope.sortBy.field = '';
              scope.sortBy.desc = false;

              return
            }

            scope.sortBy.desc = scope.sortBy.field == field && !scope.sortBy.desc;
            scope.sortBy.field = field;

            ngElement.toggleClass('desc', scope.sortBy.desc);
            ngElement.toggleClass('asc', !scope.sortBy.desc);
          })
        });
      })
    }
  }
})

core.filter('coreUnique', ['coreUtils', function ($utils) {
  return function (items, filterOn) {
    if (filterOn === false) {
      return items;
    }

    if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
      var hashCheck = {}, newItems = [];

      var extractValueToCompare = function (item) {
        if (angular.isObject(item) && angular.isString(filterOn)) {
          return $utils.getNestedField(item, filterOn);
        } else {
          return item;
        }
      };

      angular.forEach(items, function (item) {
        var valueToCheck, isDuplicate = false;

        for (var i = 0; i < newItems.length; i++) {
          if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
            isDuplicate = true;
            break;
          }
        }
        if (!isDuplicate) {
          newItems.push(item);
        }

      });
      items = newItems;
    }
    return items;
  };
}]);

core.directive('coreTinymce', ['$timeout', function ($timeout) {
  return {
    require: 'ngModel',
    scope: { options: '<', 'toFocus': '=' },
    link: function (scope, elem, attrs, ngModel) {
      const defaultOptions = {
        plugins: 'link autolink',
        promotion: false,
        menubar: false,
        statusbar: false,
        contextmenu: false,
        toolbar: 'bold italic alignleft aligncenter alignright alignjustify blockquote link',
        paste_as_text: true,
        object_resizing: false,
        paste_block_drop: true,
        content_style: 'img { max-width: 100%; max-height: 80vh; }',
        link_context_toolbar: false,
        link_default_target: '_blank',
        link_target_list: false,
        link_title: false,
        anchor_bottom: false,
        anchor_top: false
      };

      const tinymceOptions = {
        target: elem[0],

        // Update model when calling setContent (such as from the source editor popup)
        setup: function (ed) {
          ed.on('init', function (args) {
            ngModel.$render();
          });

          // Update model on button click
          ed.on('ExecCommand', function (e) {
            ed.save();

            scope.$applyAsync(() => {
              ngModel.$setViewValue(elem.val());
            });
          });

          // Update model on keypress
          ed.on('KeyUp', function (e) {
            ed.save();

            scope.$applyAsync(() => {
              ngModel.$setViewValue(elem.val());
            });
          });
        },

        ...defaultOptions,
        ...scope.options
      };

      let currentInstance = null;
      tinymce.init(tinymceOptions).then(instances => {
        currentInstance = instances[0];
        currentInstance.setContent(ngModel.$viewValue || '');
      });

      ngModel.$formatters.push(function (value) {
        if (currentInstance) {
          currentInstance.setContent(value || '');
        }

        return value;
      })

      if (scope.toFocus) {
        scope.toFocus = () => {
          $timeout(() => {
            currentInstance.selection.select(currentInstance.getBody(), true);
            currentInstance.selection.collapse(false);
            currentInstance.focus();

            currentInstance.getBody().scrollIntoView();
          })
        }
      }
    }
  }
}]);

core.filter('coreLengthByField', ['$filter', function ($filter) {
  return function (items, filterOn) {
    if (filterOn === false) {
      return items.length;
    }

    items = $filter('filter')(items,filterOn);
    return items.length;
  };
}]);