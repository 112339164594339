const inicio = angular.module('inicio', [])

inicio.config(['$stateProvider', function($stateProvider) {

    const routes = [
        {
            name: 'crmw.inicio',
            url: '/inicio',
            params: { showGlobalLoading: { dynamic: true, value: true } },
            component: 'inicio.inicioComponent'
        }
    ];

    routes.forEach($stateProvider.state);
}]);