core.component('coreChatComponent', {
    templateUrl: 'components/chat/chat.html',
    controller: 'core.chatController',
    bindings: { 
        chatPath: '<', 
        grupoPath: '<', 
        respostasPath: '<', 
        showFooter: '=', 
        showInfo: '<', 
        showOptions: '<',
        nomeRemetente: '<' , 
        modoLento: '<', 
        hiddeBtnAnexo: '=',
        onUsuarioSelect: '<'
    }
});

core.controller('core.chatController', ['$timeout', '$element', 'coreToast', 'coreFb', function($timeout, $element, $toast, $fb) {

    const $ctrl = this;

    $ctrl.formData = {};

    $ctrl.isSending = false;

    $ctrl.submitMensagem = () => {
        
        const tempoUltimoEnvio = $ctrl.modoLento && $ctrl.ultimoEnvio && new Date() - $ctrl.ultimoEnvio.data.criacao.data;
        if (tempoUltimoEnvio < $ctrl.modoLento) {
            const tempoRestante = Math.ceil(($ctrl.modoLento - tempoUltimoEnvio) / 1000);

            $toast.info(`Aguarde ${tempoRestante}s para enviar uma nova mensagem.`);

            return
        }

        if (!$ctrl.formData.mensagem || $ctrl.isSending) {
            return
        }

        $ctrl.isSending = true;

        const messageData = {
            tipo: 'Texto',
            msg: $ctrl.formData.mensagem,
            nomeRemetente: $ctrl.nomeRemetente ?? $fb.auth.getUser()?.displayName,
        };

        $ctrl.formData.mensagem = '';

        $fb.rt.add($ctrl.chatPath, messageData).then(() => {

            const grupoData = {
                ultimoEnvio: {
                    remetente: $ctrl.idUsuario,
                    ...messageData
                }
            };

            return $fb.db.update($ctrl.grupoPath, grupoData);
        }).then(() => {
            $ctrl.scrollEnd();
        }).finally(() => {
            $ctrl.isSending = false;
        });
    }

    $ctrl.isUploading = false;

    $ctrl.showBtnAnexo=()=>{
        return !$ctrl.hiddeBtnAnexo;
    }

    $ctrl.submitAnexo = (event) => {

        $ctrl.isUploading = true;

        $fb.storage.upload($ctrl.chatPath, event.target.files[0]).then((url) => {

            $ctrl.isSending = true;

            let tipoAnexo = event.target.files[0].type.startsWith('image') ? 'Imagem' : 'Documento';

            const messageData = {
                tipo: tipoAnexo,
                msg: {
                    nome: event.target.files[0].name,
                    url: url
                },
                nomeRemetente: $ctrl.nomeRemetente ?? $fb.auth.getUser()?.displayName
            };

            return $fb.rt.add($ctrl.chatPath, messageData).then(() => {

                $ctrl.scrollEnd();
            }).finally(() => {
                $ctrl.isSending = false;
            })
        }).finally(() => {
            $ctrl.isUploading = false;
        })
    }

    $ctrl.anexoDownloads = {};

    $ctrl.downloadAnexo = (anexo) => {
        $ctrl.anexoDownloads[anexo.url] = true;

        $fb.storage.downloadBlob(anexo.url, anexo.nome).finally(() => {
            $ctrl.anexoDownloads[anexo.url] = false;
        })
    };

    $ctrl.onRespostaSelect = (mensagem) => {

        $ctrl.formData.mensagem = mensagem;

        $timeout(() => {
            $element[0].querySelector('.chat-input').select();
            $element[0].querySelector('.chat-input').focus();
        })
    }

    $ctrl.showRespostas = () => {
        return $ctrl.formData.mensagem?.startsWith?.('/');
    }

    $ctrl.onKeydown = (event) => {
        if (event.key != 'Enter') {
            return
        }

        event.preventDefault();
        $ctrl.submitMensagem();
    }

    $ctrl.scrollEnd = () => {

        $ctrl.scrollElement.scrollTop = 0;
    }

    $ctrl.onScroll = () => {
        if ($ctrl.scrollElement.scrollTop > -50) {
            $ctrl.hasUnreadMessages = false;
        }
    }

    $ctrl.idUsuario = null;

    $ctrl.listaMensagens = [];
    $ctrl.listaFixadas = [];
    $ctrl.hasUnreadMessages = false;
    $ctrl.scrollElement = null;

    $ctrl.isLoading = true;

    $ctrl.ultimoEnvio = null;

    $ctrl.$onInit = () => {

        $ctrl.idUsuario = $fb.auth.getIdToken()?.claims.idUsuario ?? false;

        const _updateFixados = (mensagem) => {
            if (mensagem.data.fixado) {
                if ($ctrl.listaFixadas.find(item => item.id == mensagem.id)) {
                    return
                }

                $ctrl.listaFixadas.push(mensagem);

                return
            }

            const removedIndex = $ctrl.listaFixadas.findIndex(item => item.id == mensagem.id);
            if (removedIndex == -1) {
                return;
            }

            $ctrl.listaFixadas.splice(removedIndex, 1);
        }

        $fb.rt.listen($ctrl.chatPath, [ { limitToLast: [ 1000 ] } ], (event, item) => {
            if (event == 'changed') {
                _updateFixados(item);
            }

            if (event == 'added') {
                if ($ctrl.scrollElement?.scrollTop < -50) {
                    $ctrl.hasUnreadMessages = true;
                }

                if (item.data.criacao.idUsuario == $ctrl.idUsuario) {
                    $ctrl.ultimoEnvio = item;
                }
            }
        }).then(list => {
            [$ctrl.unsubMensagens, $ctrl.listaMensagens] = list;

            $ctrl.ultimoEnvio = $ctrl.listaMensagens.slice().reverse().find(item => item.data.criacao.idUsuario == $ctrl.idUsuario);
        }).finally(() => {
            $ctrl.isLoading = false;
        })

        // nao consultar fixadas para atendimento suporte
        if ($ctrl.showInfo && !$ctrl.respostasPath) {
            $fb.rt.list($ctrl.chatPath, [ { orderByChild: [ 'fixado' ] }, { equalTo: [ true ] } ]).then(list => {
                $ctrl.listaFixadas = list;
            })
        }
    }

    $ctrl.$postLink = () => {
        $ctrl.scrollElement = $element[0].querySelector('.scroll-y');
    }

    $ctrl.$onDestroy = () => {

        $ctrl.unsubMensagens?.();
    }
}])