contatos.component('contatos.contatosComponent', {
    templateUrl: 'contatos/contatos/contatos.html',
    controller: 'contatos.contatosController'
});

contatos.controller('contatos.contatosController', ['$scope', '$state', 'coreLanguage', 'coreDialog', 'coreFb', function($scope, $state, $text, $dialog, $fb) {
    const $ctrl = this;
    $scope.$text = $text;

    $ctrl.contatos = [];
    $ctrl.etapas = [];
    $ctrl.atendentes = [];
    $ctrl.filtro = {};
    $ctrl.selected = {};
    $ctrl.selectedAll = false;
    $ctrl.opened=false;

    const ignoreEtapas = ["Arquivado", "Ruim"];
    $ctrl.temperaturas = ["Frio", "Quente", "Quentíssimo"];

    const _getDominio=()=>{

        $fb.db.list('crmw_dominio', [], true).then(list=>{
            list.forEach(doc=>{
                if(doc.id==='etapas'){ $ctrl.etapas = doc.data.values; }
                if(doc.id==='atendentes'){ $ctrl.atendentes = doc.data.values; }
            });
        
        });
    };

    const _getDadosContatos=()=>{
        $ctrl.loadingContatos=true;

        $fb.db.list(`crmw_contatos`, [{where:['admin.etapa', 'not-in', ignoreEtapas]}]).then(list=>{
            $ctrl.contatos = list.map(item=>{ return {...item.data, id:item.id, nomeContato:item.data.nomeContato??item.data.nome}});
            $ctrl.loadingContatos=false;

            $state.params.showGlobalLoading = false;

        }).catch(err=>{
            console.log(err);
        })

    };

    $ctrl.togglePanel=(value)=>{
        $ctrl.showPanel = $ctrl.showPanel!==value.id ? value.id : false;
        $ctrl.contatoPainel = !!$ctrl.showPanel ? value : false;
    };
    
    $ctrl.toggleShowFilters=()=>{
        $ctrl.showFiltros=!$ctrl.showFiltros;
    };

    $ctrl.toggleFieldFiltro=(field, value)=>{

        if(field.indexOf('.')===-1){
            $ctrl.filtro[field] && $ctrl.filtro[field]===value ? $ctrl.filtro[field] = null : $ctrl.filtro[field] = value;

        }else{
            let keys = field.split('.');

            if(!$ctrl.filtro[keys[0]]){ $ctrl.filtro[keys[0]] = {}; };

            $ctrl.filtro[keys[0]][keys[1]] && $ctrl.filtro[keys[0]][keys[1]]===value ? $ctrl.filtro[keys[0]] = {} : $ctrl.filtro[keys[0]][keys[1]] = value;
        }

        if($ctrl.showPanel){ $ctrl.togglePanel({id:$ctrl.showPanel}); }

    };

    $ctrl.notIn=(item)=>{
        return !ignoreEtapas.includes(item);
    }

    $ctrl.setModo=(modo)=>{
        $ctrl.modo = modo;
    };

    $ctrl.updateContatoField=(key, contato)=>{
        $ctrl.loadingContatos=true;

        let obj = {};
        obj[`admin.${key}`] = contato.admin[key];

        $fb.db.update(`crmw_contatos/${contato.id}`, obj).then(okay=>{
            $ctrl.loadingContatos=false;

        }).catch(err=>{
            console.log(err);
        })

    };

    $scope.uploadContatos=async(file)=>{
        $scope.loadingCSV=true;

        let contatosFile = file[0];

        $ctrl.contatosList=[];
        let added = 0;

        const reader = new FileReader();
        reader.onload = (async(evt)=>{
            $state.params.showGlobalLoading = true;

            const now = new Date();
            const arquivo = evt.target.result;
            const lines = arquivo.split('\n');

            for(let line of lines){
                let campos = line.split(',');
                
                if(campos[0]==='nome'||campos[0]===''){
                    added++;
                    continue;
                }

                let obj = {
                    nome: campos[0],
                    especialidade: campos[1] ?? '',
                    nomeContato: campos[2] ?? '',
                    origem: campos[3] ?? 'importação',
                    telefone: campos[4].replaceAll(' ', ''),
                    whatsapp: campos[5] ?? '',
                    //whatsapp: '553791432207@s.whatsapp.net',
                    email: campos[6] ?? '',
                    site:campos[7] ?? '',
                    admin:{
                        etapa: campos[8] ?? 'Inicial',
                        responsavel: campos[9] ?? '',
                        temperatura: campos[10] ?? 'Frio',
                        observacoes:[]
                    },
                    endereco: campos[13] ?? '',
                }

                if(!!campos[11] && campos[11] !== ""){
                    obj.admin.observacoes.push({data:now.getTime(), text:campos[11]});
                }

                if(!!campos[12] && campos[12] !== ""){ 
                    let ultima = new Date(campos[12]);
                    obj.ultimaMsg = ultima.getTime();
                }

                if(campos.length>14){
                    let i = 13;
                    for(i=14; i < campos.length; i++){ obj.endereco += campos[i];}
                }

                await $fb.db.add(`crmw_contatos`, obj);

                added++;

                if(added>=lines.length){
                    $state.reload();
                }
            }

        });

        reader.readAsText(contatosFile);

    };

    $ctrl.toggleAll=(list)=>{
        $ctrl.selected = {};

        console.log($ctrl.selectedAll, list.length);

        if($ctrl.selectedAll === true){
            list.forEach(item=>{ if(!!item.id){ $ctrl.selected[item.id] = true; } });
        }

    };

    $ctrl.removeSelected=(id)=>{
        if($ctrl.selected[id]===false){ delete $ctrl.selected[id]; }
    };

    $ctrl.hasSelected=()=>{
        return !!$ctrl.selected && Object.values($ctrl.selected).length > 0;
    };

    $ctrl.openSendModal=()=>{
        $ctrl.opened=true;
    };

    $ctrl.$onInit = () => {
        $ctrl.setModo('lista');

        _getDadosContatos();
        _getDominio();
    };
    
}]);