core.component('coreScrollComponent', {
    templateUrl: 'components/scroll/scroll.html',
    controller: 'core.scrollController',
    transclude: true
});

core.controller('core.scrollController', ['$scope', '$element', '$transclude', '$timeout', function($scope, $element, $transclude, $timeout) {

    const $ctrl = this;

    $ctrl.scrollElement = null;
    $ctrl.showBack = false;
    $ctrl.showForward = false;

    $ctrl.moveScroll = (forward = true) => {

        const containerWidth = $ctrl.scrollElement.clientWidth;

        $ctrl.scrollElement.scrollBy({
            left: (forward ? containerWidth : -containerWidth) * 0.5,
            behavior: 'smooth'
        });
    }

    $ctrl.checkScroll = () => {
        const scrollLeft = $ctrl.scrollElement.scrollLeft;
        const scrollWidth = $ctrl.scrollElement.scrollWidth;
        const containerWidth = $ctrl.scrollElement.clientWidth;

        $ctrl.showBack = scrollLeft > 10;
        $ctrl.showForward = scrollLeft < scrollWidth - containerWidth - 10;
    }

    $ctrl.$onInit = () => {

        $transclude(clone => {
            $element.prepend(clone);
            $ctrl.scrollElement = $element[0].querySelector('.scroll-x');
        })

        angular.element($ctrl.scrollElement).on("scroll", () => {
            $scope.$evalAsync($ctrl.checkScroll());
        })

        angular.element($ctrl.scrollElement).on("wheel", (e) => {
            const scrollWidth = $ctrl.scrollElement.scrollWidth;
            const containerWidth = $ctrl.scrollElement.clientWidth;

            if (scrollWidth == containerWidth) {
                return
            }

            e.preventDefault();

            $ctrl.scrollElement.scrollLeft += e.deltaY;
        })

        $scope.$applyAsync(() => {
            $ctrl.checkScroll();
        })
    }
}])