auth.component('auth.registerComponent', {
    templateUrl: 'auth/register/register.html',
    controller: 'auth.registerController'
});

auth.controller('auth.registerController', ['$scope', 'coreFb', 'coreUtils', '$state', 'coreLanguage', function($scope, $fb, $utils, $state, $text) {

    const $ctrl = this;
    $scope.$text = $text;

    $ctrl.isSubmiting = false;
    $ctrl.submitError = '';
    $ctrl.submitSuccess = false;
    $ctrl.showPassword = false;
    $ctrl.ddiOptions = [];

    $ctrl.formData = {
        nome: '',
        telefone:{
            ddi:'55',
            numero:''
        },
        email: '',
        senha: '',
        senhaConfirma: '',
        termos: '',
    };

    $ctrl.formErrors = {
        'nome': {},
        'telefone.ddi':{},
        'telefone.numero': {},
        'email': {},
        'senha': {},
        'senhaConfirma': {},
        'termos': {},
    };

    $ctrl.togglePassword = () => {
        $ctrl.showPassword = !$ctrl.showPassword;
    }

    $ctrl.isPasswordStep = (step) => {
        if (!$scope.registerForm) {
            return false
        }

        return Object.keys($scope.registerForm.senha.$error).length < step;
    }

    $ctrl.validate = {
        nome(value) {
            // Precisa conter ao menos 1 caractere após um ' ' (espaço).
            return value.match(/.+ .+/)
        },
        email(value) {
            // Precisa conter @ e pelo menos um '.' (ponto) após o @
            return value.match(/.+@.+\./)
        },
        senha: {
            // 8 ou mais caracteres
            length(value) {
                return value.length >= 8
            },
            // Pelo menos 1 letra maíuscula
            letter(value) {
                return value.match(/(?=.*[A-Z]).+/)
            },
            // Pelo menos 1 número
            number(value) {
                return value.match(/(?=.*[0-9]).+/)
            },
            // Pelo menos 1 Caracteres especial
            special(value) {
                return value.match(/(?=.*[^a-zA-Z0-9]).+/)
            }
        },
        senhaConfirma(value) {
            // Precisa ser idêntica à senha
            return value == $ctrl.formData.senha
        }
    }

    $ctrl.onBlur = (name) => {

        $ctrl.formErrors[name] = {
            field: !$scope.registerForm[name].$valid,
            hint: !$scope.registerForm[name].$valid
        };
    }

    $ctrl.validateFields = () => {

        Object.keys($ctrl.formErrors).forEach((name) => {
            $ctrl.formErrors[name] = {
                field: !$scope.registerForm[name].$valid,
                hint: !$scope.registerForm[name].$valid
            }
        })
    }

    $ctrl.onSubmit = () => {

        if (!$scope.registerForm.$valid) {
            $ctrl.validateFields();
            return
        }

        $ctrl.isSubmiting = true;
        $ctrl.submitError = '';
        $ctrl.submitSuccess = false;

        const authData = {
            nivel: 2,
            ...$ctrl.formData
        };

        $fb.functions.invoke('auth_register', authData).then(() => {

            $ctrl.submitSuccess = true;

            $fb.auth.login($ctrl.formData.email, $ctrl.formData.senha).then(() => {
                $state.go('crmw');
            });

        }).catch((e) => {

            window.scrollTo(0, 0);

            if (e.code == 'functions/already-exists') {
                $ctrl.submitError = 'already-exists';
                $ctrl.errorMsg = $text.emailAlreadyExists;

            } else {
                $ctrl.submitError = 'internal';
                $ctrl.errorMsg = $text.somethingWrong;
                throw e
            }
        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    }

    $ctrl.$onInit = () => {

        if ($fb.auth.getUser()) {
            $state.go('crmw');
        }

        $fb.db.get('/dominio/ddi').then((data) => {
            if(!data.data){ console.log('Falta: dominio/ddi'); }
            $ctrl.ddiOptions = data.data.values;
        });
    }
}])