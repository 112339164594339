usuariobkp.component('usuario.dadosComponent', {
    templateUrl: 'usuario/usuario/dados/dados.html',
    controller: 'usuario.dadosController',
    bindings: { baseUsuario: '<' }
});

usuariobkp.controller('usuario.dadosController', ['$scope', '$state', 'coreUtils', 'coreToast', 'coreFb', 'coreLanguage', function($scope, $state, $utils, $toast, $fb, $text) {

    $scope.$text = $text;
    const $ctrl = this;

    $ctrl.usuarioLogout = () => {

        $fb.auth.logout().then(() => {
            $state.go('login');
        })
    }

    $ctrl.formData = {};

    $ctrl.dadosData = {};
    $ctrl.dadosErrors = {
        'nome': {},
        'telefone.ddi': {},
        'telefone.numero': {},
        'cpf': {},
        'nascimento': {},
        'endereco.cep': {},
        'endereco.logradouro': {},
        'endereco.numero': {},
        'endereco.bairro': {},
        'endereco.complemento': {},
        'endereco.cidade': {},
        'endereco.uf': {},
    };

    $ctrl.validateDadosField = (name) => {

        $ctrl.dadosErrors[name] = {
            field: $scope.usuarioDadosForm[name].$invalid,
            hint: $scope.usuarioDadosForm[name].$invalid
        };
    }

    $ctrl.validateDadosFields = () => {

        Object.keys($ctrl.dadosErrors).forEach(name => {
            $ctrl.dadosErrors[name] = {
                field: $scope.usuarioDadosForm[name].$invalid,
                hint: $scope.usuarioDadosForm[name].$invalid
            }
        })
    }

    $ctrl.validate = {
        nome(value) {
            // Precisa conter ao menos 1 caractere após um ' ' (espaço).
            return value?.match(/.+ .+/)
        },
        cpf(value) {
            // Precisa conter um número de CPF Válido
            return value && $utils.validaCpf(value)
        }
    }

    $ctrl.isSubmiting = false;
    $ctrl.submitError = '';
    $ctrl.submitSuccess = false;

    $ctrl.getModifiedDadosData = () => {

        const modifiedData = {};

        Object.keys($ctrl.dadosData).forEach(name => {
            if ($scope.usuarioDadosForm[name].$dirty) {
                modifiedData[name] = $ctrl.dadosData[name];
            }
        })

        return modifiedData;
    }

    $ctrl.submitDadosForm = () => {

        if (!$scope.usuarioDadosForm.$valid) {
            $ctrl.validateDadosFields();
            return
        }

        $ctrl.formErrors = {};

        $ctrl.isSubmiting = true;
        $ctrl.submitError = '';
        $ctrl.submitSuccess = false;

        const dadosData = $ctrl.getModifiedDadosData();
        $fb.db.update(`/usuarios/${$ctrl.baseUsuario.id}`, dadosData).then(() => {

            $scope.usuarioDadosForm.$setPristine();

            $toast.success($text.successfullUpdate);
            $scope.$emit('update-usuario');
        }).catch(e => {
            $ctrl.submitError = 'internal';

            throw e;
        }).finally(() => {
            $ctrl.isSubmiting = false;
        })
    }

    $ctrl.cepState = {
        isLoading: false,
        hasError: false
    }

    $ctrl.onCepUpdate = () => {

        if ($scope.usuarioDadosForm['endereco.cep'].$pristine || !$ctrl.dadosData['endereco.cep'] || $scope.usuarioDadosForm['endereco.cep'].$invalid) {
            return
        }

        $ctrl.cepState.isLoading = true;
        $ctrl.cepState.hasError = false;

        $utils.httpReq('get', `https://viacep.com.br/ws/${$ctrl.dadosData['endereco.cep']}/json`).then(res => {
            if (res?.data.erro) {
                $ctrl.cepState.hasError = true;

                return
            }

            const enderecoData = {
                'endereco.logradouro': res.data.logradouro,
                'endereco.bairro': res.data.bairro,
                'endereco.complemento': res.data.complemento,
                'endereco.cidade': res.data.localidade,
                'endereco.uf': res.data.uf,
            }

            Object.assign($ctrl.dadosData, enderecoData);

            $scope.usuarioDadosForm['endereco.logradouro'].$setDirty();
            $scope.usuarioDadosForm['endereco.bairro'].$setDirty();
            $scope.usuarioDadosForm['endereco.complemento'].$setDirty();
            $scope.usuarioDadosForm['endereco.cidade'].$setDirty();
            $scope.usuarioDadosForm['endereco.uf'].$setDirty();
        }).finally(() => {
            $ctrl.cepState.isLoading = false;
        });
    }

    $ctrl.ddiOptions = [];
    $ctrl.estadoOptions = [];

    $ctrl.$onInit = () => {

        //$ctrl.deregisterOnCepUpdated = $scope.$watch("$ctrl.dadosData['endereco.cep']", $ctrl.onCepUpdate);

        /* $fb.db.get('/dominio/ddi').then(res => {
            if(!res.data){ console.log('Not Found: dominio/ddi'); return false; }
            $ctrl.ddiOptions = res.data.values;
        })

        $fb.db.get('/dominio/estados').then(res => {
            if(!res.data){ console.log('Not Found: dominio/estados'); return false; }
            $ctrl.estadoOptions = res.data.values;
        }) */
    }

    $ctrl.$onChanges = (changes) => {

        if (changes.baseUsuario && changes.baseUsuario.currentValue) {
            const baseUsuario = changes.baseUsuario.currentValue.data;
            $ctrl.dadosData = {
                'nome': baseUsuario.nome,
                'telefone.ddi': baseUsuario.telefone?.ddi?.toString(),
                'telefone.numero': baseUsuario.telefone?.numero,
                'cpf': baseUsuario.cpf,
                'nascimento': baseUsuario.nascimento?.toDate(),
                'endereco.cep': baseUsuario.endereco?.cep,
                'endereco.logradouro': baseUsuario.endereco?.logradouro,
                'endereco.numero': baseUsuario.endereco?.numero,
                'endereco.bairro': baseUsuario.endereco?.bairro,
                'endereco.complemento': baseUsuario.endereco?.complemento,
                'endereco.cidade': baseUsuario.endereco?.cidade,
                'endereco.uf': baseUsuario.endereco?.uf,
            };

            $ctrl.formData.email = baseUsuario.email;

            (baseUsuario.telefone&&baseUsuario.telefone.ddi) && $ctrl.ddiOptions.push({ ddi: baseUsuario.telefone.ddi.toString() });
        }
    }
}])