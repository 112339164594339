core.component('coreChartComponent', {
    templateUrl: 'components/chart/chart.html',
    controller: 'core.chartController',
    bindings: { options: '<' }
});

core.controller('core.chartController', ['$scope', '$element', function($scope, $element) {
    const $ctrl = this;

    $ctrl.chartInstance = null;

    $ctrl.$onInit = () => {
        $ctrl.chartInstance = new ApexCharts($element.children()[0], $ctrl.options);

        $scope.$applyAsync(() => {
            $ctrl.chartInstance.render();
        })
    }
}])