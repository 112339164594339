crmw.component('layoutComponent', {
    templateUrl: 'components/layout/layout.html',
    controller: 'layoutComponentController'
});

crmw.controller('layoutComponentController', ['$state', 'coreLanguage', function($state, $text) {
    const $ctrl = this;

    $ctrl.showMenu = () => $state.params.showMenu != false;
    $ctrl.hideNav = () => $state.params.hideNav == true;

    $ctrl.menuItems = [
        {
            title: $text.menu.home,
            route: 'crmw.inicio',
            icon: 'home'
        },
        {
            title: $text.menu.contacts,
            route: 'crmw.contatos',
            icon: 'person'
        }
    ];

}])