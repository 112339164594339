core.component('coreChatFixadoComponent', {
    templateUrl: 'components/chat/fixado.html',
    controller: 'core.chatFixadoController',
    bindings: { listaMensagens: '<', showOptions: '<' }
});

core.controller('core.chatFixadoController', ['$scope', 'coreDialog', 'coreFb', function($scope, $dialog, $fb) {

    const $ctrl = this;

    $ctrl.showLista = false;

    $ctrl.toggleLista = () => {

        $ctrl.showLista = !$ctrl.showLista;
    }

    $ctrl.toggleFixado = (mensagem) => {

        const callback = () => {
            const mensagemData = { fixado: false };
            return $fb.rt.update(`${mensagem.path}/${mensagem.id}`, mensagemData);
        }

        $dialog.show(undefined, undefined, undefined, undefined, undefined, callback).catch(() => {
            // reject
        });
    }

    $ctrl.$onInit = () => {

    }
}])