inicio.component('inicio.inicioComponent', {
    templateUrl: 'inicio/inicio/inicio.html',
    controller: 'inicio.inicioController'
});

inicio.controller('inicio.inicioController', ['$scope', '$state', 'coreDialog', 'coreFb', function($scope, $state, $dialog, $fb) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
       
        $state.params.showGlobalLoading = false;
    }
}]);