const usuariobkp = angular.module('usuario', [])

usuariobkp.config(['$stateProvider', function($stateProvider) {

    const routes = [
        {
            name: 'usuario',
            redirectTo: 'crmw.usuario'
        },
        {
            name: 'crmw.usuario',
            url: '/minhaconta/{view:dados|contas}',
            params: { view: { dynamic: true, value: 'dados' }, showGlobalLoading: { dynamic: true, value: true } },
            component: 'usuario.usuarioComponent'
        }
    ];

    routes.forEach($stateProvider.state);
}]);