core.component('coreToastComponent', {
    templateUrl: 'components/toast/toast.html',
    controller: ['$rootScope', 'coreToast', function($rootScope, coreToast){
        var $ctrl = this;
        $rootScope.$watch('toasts', (newVal, oldVal)=>{ $ctrl.toasts = newVal; });
        $ctrl.close=(idx)=>{ coreToast.hidde(idx); };
        $ctrl.isType=(tipo, toastTipo)=>{ return tipo===toastTipo; };

        //Como usar -- (Injetar coreToast)
        //coreToast.fail("Ocorreu um problema. Tente novamente.");
        //coreToast.success('Salvo com sucesso!');
        //coreToast.info('Suas informações precisam ser confirmadas.');
        //coreToast.generic('Hello World!', 'Mensagem');
    }],
    controllerAs: '$ctrl'
});

core.factory('coreToast', ["$rootScope", "$timeout", function($rootScope, $timeout) {
    const mensagensPadrao = {
        Sucesso: 'A ação foi concluída.',
        Falhou: 'Ocorreu um erro. Tente novamente.'
    };

    const coreToast = {};
    $rootScope.toasts = [];

    coreToast.add=(toast)=>{
        toast.ativo = true;
        toast.msg = toast.msg ?? mensagensPadrao[toast.tipo] ?? '';
        $rootScope.toasts.push(toast);
        $timeout(()=>{ if (!$rootScope.toasts[0]) return; $rootScope.toasts[0].ativo=false; $rootScope.toasts.splice(0, 1); }, 7300);
    };

    coreToast.hidde=(idx)=>{
        $rootScope.toasts[idx].ativo=false;
        $timeout(()=>{ $rootScope.toasts.splice(idx, 1); }, 300);
    };

    coreToast.fail=(msg)=>{
        coreToast.add({msg:msg, tipo:"Falhou"});
    };

    coreToast.success=(msg)=>{
        coreToast.add({msg:msg, tipo:"Sucesso"});
    };

    coreToast.info=(msg)=>{
        coreToast.add({msg:msg, tipo:"Info"});
    };

    coreToast.generic=(msg, titulo="Mensagem")=>{
        coreToast.add({msg:msg, tipo:titulo});
    };

    return coreToast;

}]);