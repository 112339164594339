const crmw = angular.module('crmw', ['core', 'auth', 'usuario', 'inicio', 'contatos']);

crmw.config(['coreFbProvider', (coreFbProvider) => {
    coreFbProvider.firebaseConfig(APP_CONSTANTS.FIREBASE_CONFIG);
}])

crmw.config(['$stateProvider', '$urlServiceProvider', function($stateProvider, $urlServiceProvider) {

    const routes = [
        {
            name: 'crmw',
            url: '',
            redirectTo: 'crmw.inicio',
            component: 'layoutComponent',
            data: { requiresAuth: true }
        },
        {
            name: 'crmw.erro',
            url: '/erro',
            templateUrl: 'components/error/error.html',
            controller: ['$scope','coreLanguage', function($scope, $text){ $scope.$text = $text; }]
        },
        {
            name: 'crmw.erroPermissao',
            url: '/erroPermissao',
            templateUrl: 'components/error/permissao.html',
            controller: ['$scope','coreLanguage', function($scope, $text){ $scope.$text = $text; }]
        },
        {
            name: 'erroPermissao',
            redirectTo: 'crmw.erroPermissao',
        }
    ];

    routes.forEach($stateProvider.state);

    $urlServiceProvider.rules.otherwise({ state: 'crmw.erro', options: { location: false } });
}]);