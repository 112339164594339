auth.component('auth.resendComponent', {
    templateUrl: 'auth/resend/resend.html',
    controller: 'auth.resendController'
});

auth.controller('auth.resendController', ['$scope', 'coreFb', 'coreLanguage', function($scope, $fb, $text) {

    $scope.$text = $text;
    const $ctrl = this;

    $ctrl.isSubmiting = false;
    $ctrl.submitError = false;
    $ctrl.submitSuccess = false;

    $ctrl.formData = {
        email: ''
    };

    $ctrl.formErrors = {
        email: {
            field: false,
            hint: false
        }
    };

    $ctrl.onBlur = (name) => {

        $ctrl.formErrors[name] = {
            field: !$scope.form.resendForm[name].$valid,
            hint: !$scope.form.resendForm[name].$valid
        };
    }

    $ctrl.validateFields = () => {

        Object.keys($ctrl.formErrors).forEach((name) => {
            $ctrl.formErrors[name] = {
                field: !$scope.form.resendForm[name].$valid,
                hint: !$scope.form.resendForm[name].$valid
            }
        })
    }

    $ctrl.onSubmit = () => {

        if (!$scope.form.resendForm.$valid) {
            $ctrl.validateFields();
            return
        }

        $ctrl.formData.email = $ctrl.formData.email.toLowerCase();

        $ctrl.isSubmiting = true;
        $ctrl.submitError = false;

        $fb.auth.resendAcessEmail($ctrl.formData.email).then((res) => {
            $ctrl.submitSuccess = true;

        }).catch(() => {

            $ctrl.submitError = true;

            $ctrl.formErrors.email = {
                field: true,
                hint: false
            };

        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    }

    $ctrl.$onInit = () => {
        $scope.form={};
    }
}]);