auth.component('auth.recoverComponent', {
    templateUrl: 'auth/recover/recover.html',
    controller: 'auth.recoverController'
});

auth.controller('auth.recoverController', ['$scope', 'coreFb', 'coreLanguage', function($scope, $fb, $text) {
    
    $scope.$text = $text;
    const $ctrl = this;

    $ctrl.isSubmiting = false;
    $ctrl.submitError = false;
    $ctrl.submitSuccess = false;

    $ctrl.formData = {
        email: ''
    };

    $ctrl.formErrors = {
        email: {
            field: false,
            hint: false
        }
    };

    $ctrl.onBlur = (name) => {

        $ctrl.formErrors[name] = {
            field: !$scope.recoverForm[name].$valid,
            hint: !$scope.recoverForm[name].$valid
        };
    }

    $ctrl.validateFields = () => 

        Object.keys($ctrl.formErrors).forEach((name) => {
            $ctrl.formErrors[name] = {
                field: !$scope.recoverForm[name].$valid,
                hint: !$scope.recoverForm[name].$valid
            }
        })
    

    $ctrl.onSubmit = () => {

        if (!$scope.recoverForm.$valid) {
            $ctrl.validateFields();
            return
        }

        $ctrl.formData.email = $ctrl.formData.email.toLowerCase();

        $ctrl.isSubmiting = true;
        $ctrl.submitError = false;

        $fb.auth.resetPassword($ctrl.formData.email).then(() => {

            $ctrl.submitSuccess = true;
        }).catch(() => {

            $ctrl.submitError = true;

            $ctrl.formErrors.email = {
                field: true,
                hint: false
            };

            $ctrl.formErrors.password = {
                field: true,
                hint: false
            };
        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    }

}]);
