usuariobkp.component('usuario.usuarioComponent', {
    templateUrl: 'usuario/usuario/usuario.html',
    controller: 'usuario.usuarioController'
});

usuariobkp.controller('usuario.usuarioController', ['$scope', '$state', 'coreFb', 'coreLanguage', function($scope, $state, $fb, $text) {

    $scope.$text = $text;
    const $ctrl = this;

    $ctrl.isView = (view) => {
        return $state.params.view == view;
    }

    $ctrl.baseUsuario = null;

    $ctrl.onUsuarioUpdate = () => {
        const idUsuario = $fb.auth.getIdToken().claims.idUsuario;
        $fb.db.get(`/usuarios/${idUsuario}`).then(res => {
            $ctrl.baseUsuario = res;
        })
    }

    $ctrl.$onInit = () => {

        $ctrl.deregisterOnUsuarioUpdated = $scope.$on('update-usuario', $ctrl.onUsuarioUpdate);

        const idUsuario = $fb.auth.getIdToken().claims.idUsuario;
        $fb.db.get(`/usuarios/${idUsuario}`).then(res => {
            $ctrl.baseUsuario = res;
        }).finally(() => {
            $state.params.showGlobalLoading = false;
        })
    }
}])