contatos.component('contatos.dadosComponent', {
    templateUrl: 'contatos/dados/dados.html',
    controller: 'contatos.dadosController',
    controllerAs:'$ctrl',
    bindings:{contato:'=', etapas:'<', atendentes:'<'}
});

contatos.controller('contatos.dadosController', ['$scope', '$state', 'coreFb', 'coreLanguage', function($scope, $state, $fb, $text) {
    $scope.$text = $text;
    const $ctrl = this;

    $ctrl.toggleAddObs=()=>{
        $ctrl.showObs=!$ctrl.showObs;
        $ctrl.obsView = '';
    };

    $ctrl.toggleEditContato=()=>{
        $ctrl.modoEdit=!$ctrl.modoEdit;
    };

    $ctrl.setTemperatura=(value)=>{
        $ctrl.contato.admin.temperatura = value;
        $ctrl.updateContatoField('temperatura', $ctrl.contato);
    };

    $ctrl.addObs=()=>{
        $ctrl.loadingContato=true;
        if(!$ctrl.contato.admin.observacoes){ $ctrl.contato.admin.observacoes=[]; }

        let tempo = new Date();

        $fb.db.update(`crmw_contatos/${$ctrl.contato.id}`, {'admin.observacoes':'_union:{"data":'+tempo.getTime()+', "text":"'+angular.copy($ctrl.obsView)+'"}'}).then(okay=>{
            $ctrl.contato.admin.observacoes.push({data:tempo.getTime(), text:angular.copy($ctrl.obsView)});
            $ctrl.toggleAddObs();
            $ctrl.loadingContato=false;
        }).catch(err=>{
            console.log(err);
        });
        
    };

    $ctrl.updateContato=()=>{
        $ctrl.loadingContato=true;

        $fb.db.update(`crmw_contatos/${$ctrl.contato.id}`, $ctrl.contato).then(okay=>{
            $ctrl.toggleEditContato();
            $ctrl.loadingContato=false;

        }).catch(err=>{
            console.log(err);
        })
    };

    $ctrl.updateContatoField=(key, contato)=>{
        $ctrl.loadingContatos=true;

        let obj = {};
        obj[`admin.${key}`] = contato.admin[key];

        $fb.db.update(`crmw_contatos/${contato.id}`, obj).then(okay=>{
            $ctrl.loadingContatos=false;

        }).catch(err=>{
            console.log(err);
        })

    };

    $ctrl.$onInit=()=>{
        $state.params.showGlobalLoading = false;
        $ctrl.modoEdit = false;
        $ctrl.showObs = false;
    };

}]);