auth.component('auth.loginComponent', {
    templateUrl: 'auth/login/login.html',
    controller: 'auth.loginController',
    bindings: { returnTo: '<' }
});

auth.controller('auth.loginController', ['$scope', '$state', 'coreFb', 'coreLanguage', function($scope, $state, $fb, $text) {

    $scope.$text = $text;
    const $ctrl = this;

    $ctrl.isSubmiting = false;
    $ctrl.submitError = false;
    $ctrl.submitSuccess = false;
    $ctrl.showPassword = false;

    $ctrl.formData = {
        email: '',
        password: ''
    };

    $ctrl.formErrors = {
        email: {
            field: false,
            hint: false
        },
        password: {
            field: false,
            hint: false
        },
    };

    $ctrl.togglePassword = () => {

        $ctrl.showPassword = !$ctrl.showPassword;
    }

    $ctrl.onBlur = (name) => {

        $ctrl.formErrors[name] = {
            field: !$scope.loginForm[name].$valid,
            hint: !$scope.loginForm[name].$valid
        };
    }

    $ctrl.validateFields = () => {

        Object.keys($ctrl.formErrors).forEach((name) => {
            $ctrl.formErrors[name] = {
                field: !$scope.loginForm[name].$valid,
                hint: !$scope.loginForm[name].$valid
            }
        })
    }

    $ctrl.onSubmit = () => {

        if (!$scope.loginForm.$valid) {
            $ctrl.validateFields();
            return
        }

        $ctrl.formData.email = $ctrl.formData.email.toLowerCase();

        $ctrl.isSubmiting = true;
        $ctrl.submitError = false;

        $ctrl.formErrors.email = {
            field: false,
            hint: false
        };

        $ctrl.formErrors.password = {
            field: false,
            hint: false
        };

        $fb.auth.login($ctrl.formData.email, $ctrl.formData.password).then((cred) => {

            return cred.user.getIdTokenResult().then((res) => {
                if (!res.claims.nivel || res.claims.nivel < 1) {
                    $fb.auth.logout();
                    throw new Error()
                }

                $ctrl.submitSuccess = true;

                if ($ctrl.returnTo) {
                    $state.go($ctrl.returnTo.state().name, $ctrl.returnTo.params());
                } else {
                    $state.go('crmw');
                }
            })
        }).catch(() => {

            $ctrl.submitError = true;

            $ctrl.formErrors.email = {
                field: true,
                hint: false
            };

            $ctrl.formErrors.password = {
                field: true,
                hint: false
            };

            $ctrl.checkError(e);

        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    }

    $ctrl.checkError=(error)=>{

        if(error.code==="auth/user-not-found"){
            $ctrl.errorMsg = $text.errNoAccount; 
        }else if(error.code==="auth/wrong-password"){
            $ctrl.errorMsg = $text.wrongPass; 
        }else if(error.code==="auth/user-disabled"){
            $ctrl.errorMsg = $text.blockedAccount;
        }else if(error.code==="auth/too-many-requests"){
            $ctrl.errorMsg = $text.temporaryBlockedAccount;
        }else if(!error.code&&error.message.search('user denied permission')!==-1){ 
            $ctrl.errorCode = 'auth/storage-unavailable'; 
            $state.reload();
        }else{ 
            $ctrl.errorMsg = $text.genericError;
        }

    };

    $ctrl.$onInit = () => {

        if ($fb.auth.getUser()) {
            $state.go('crmw');
        }
    }
}])