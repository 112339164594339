auth.component('auth.loginWithLinkComponent', {
    templateUrl: 'auth/login/loginWithLink/loginWithLink.html',
    controller: 'auth.loginWithLinkController'
});

auth.controller('auth.loginWithLinkController', ['$scope', '$state', 'coreFb', 'coreUtils', 'coreLanguage', function($scope, $state, $fb, $utils, $text) {

    $scope.$text = $text;
    const $ctrl = this;

    $ctrl.isSubmiting = false;
    $ctrl.submitError = false;
    $ctrl.submitSuccess = false;

    $ctrl.checkLocalStorage=()=>{
        if(!$utils.localStorageAvailable()){
            $scope.storageUnavailable=true;
        }
    };

    $ctrl.formData = {
        email: '',
    };

    $ctrl.formErrors = {
        email: {
            field: false,
            hint: false
        }
    };

    $ctrl.onBlur = (name) => {

        $ctrl.formErrors[name] = {
            field: !$scope.form.loginForm[name].$valid,
            hint: !$scope.form.loginForm[name].$valid
        };
    };

    $ctrl.validateFields = () => {

        Object.keys($ctrl.formErrors).forEach((name) => {
            $ctrl.formErrors[name] = {
                field: !$scope.form.loginForm[name].$valid,
                hint: !$scope.form.loginForm[name].$valid
            }
        })
    };

    $ctrl.onSubmit = () => {

        if (!$scope.form.loginForm.$valid) {
            $ctrl.validateFields();
            return
        }

        $ctrl.formData.email = $ctrl.formData.email.toLowerCase();

        $ctrl.isSubmiting = true;
        $ctrl.submitError = false;

        $ctrl.formErrors.email = {
            field: false,
            hint: false
        };

        $fb.auth.sendLoginLink($ctrl.formData.email).then((cred) => {

            localStorage.setItem("signInEmail", $ctrl.formData.email);
            $ctrl.submitSuccess = true;
            
        }).catch(() => {

            $ctrl.submitError = true;
            $ctrl.submitSuccess = false;

            $ctrl.formErrors.email = {
                field: true,
                hint: false
            };

        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    };

    $ctrl.submitLoginLink = ()=> {
        $fb.auth.loginWithLink().then((cred) => {

            return cred.user.getIdTokenResult().then((res) => {
                if (!res.claims.nivel || res.claims.nivel < 1) {
                    $fb.auth.logout();
                    throw new Error();
                }

                $ctrl.submitSuccess = true;

                if ($ctrl.returnTo) {
                    $state.go($ctrl.returnTo.state().name, $ctrl.returnTo.params());
                } else {
                    $state.go('crmw');
                }
            })
        }).catch(() => {

            $ctrl.submitError = true;

            $ctrl.formErrors.email = {
                field: true,
                hint: false
            };

        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    };

    $ctrl.$onInit = () => {

        $scope.form={};

        $ctrl.checkLocalStorage();

        if ($fb.auth.getUser()) { $state.go('crmw'); }

        if(window.location.href.search("&mode=signIn&")!==-1){ 
            $ctrl.submitSuccess=true;
            $ctrl.submitLoginLink(); 
        }
    }
}]);