core.component('menuComponent', {
    templateUrl: 'components/menu/menu.html',
    controller: 'menuComponentController',
    bindings: { hideNav: '<', menuItems:'<' }
});

core.controller('menuComponentController', ['$state', '$transitions', 'coreLanguage', function($state, $transitions, $text) {
    const $ctrl = this;

    $ctrl.accountLabel = $text.menu.account;
    $ctrl.showDrawer = false;

    $ctrl.isActive = (route) => {
        return $state.current.name.startsWith(route);
    }

    $ctrl.toggleDrawer = () => {
        $ctrl.showDrawer = !$ctrl.showDrawer;
    }

    $transitions.onFinish({}, () => {
        $ctrl.showDrawer = false;
    });
}])