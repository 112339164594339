import * as App from "firebase/app";
import * as Auth from "firebase/auth";
import * as Storage from "firebase/storage";
import * as Database from 'firebase/database';
import * as Firestore from 'firebase/firestore';
import * as Functions from 'firebase/functions';
import { getMessaging, getToken } from 'firebase/messaging';

function FirebaseService($rootScope, $q, $utils, config) {
    const app = App.initializeApp(config);

    const auth = {};
    auth.instance = Auth.getAuth();

    auth.login = (email, password) => {
        return $q.when(Auth.signInWithEmailAndPassword(auth.instance, email, password)).then((cred) => {
            _requestPermission();
            return cred;
        });
    }

    auth.logout = () => {
        return $q.when(Auth.signOut(auth.instance)).then(() => {
            return;
        });
    }

    auth.resetPassword = (email) => {
        return $q.when(Auth.sendPasswordResetEmail(auth.instance, email)).then(() => {
            return;
        })
    }

    auth._idToken = null;

    auth.waitUser = () => {
        return $q(resolve => {
            if (auth.instance.currentUser) {
                resolve(auth.instance.currentUser);
            }

            const deregisterListener = Auth.onAuthStateChanged(auth.instance, user => {
                deregisterListener();

                if (!user) {
                    resolve(user);

                    return
                }

                user.getIdTokenResult().then(res => {
                    auth._idToken = res;

                    resolve(user);
                })
            })
        })
    }

    auth.getUser = () => {
        return auth.instance.currentUser;
    }

    auth.getIdToken = () => {
        return auth._idToken;
    }

    auth.resendAcessEmail= (email) =>{
        return functions.invoke('auth_resendAccessEmail', { email: email });
    };

    auth.sendLoginLink = (email) =>{
        const actionCodeSettings = {
            url: 'https://'+window.location.hostname+'/#!/loginWithLink',
            handleCodeInApp: true
        };

        return $q.when(Auth.sendSignInLinkToEmail(auth.instance, email, actionCodeSettings))
        .then(() => { return; });
    };

    auth.loginWithLink = () =>{
        let signInEmail =  $utils.localStorageAvailable()?localStorage.getItem("signInEmail"):sessionStorage.getItem("signInEmail");

        return $q.when(Auth.signInWithEmailLink(auth.instance, signInEmail, window.location.href)).then((cred) => {
            auth.waitUser().then(okay=>{ _requestPermission(); });
            return cred;
        });
    };

    const storage = {};
    storage.instance = Storage.getStorage();

    storage.upload = (path, file, name) => {

        name = name ? `${name}_${$utils.randomId(5)}` : $utils.randomId();

        const ext = file.name.split('.').pop();
        name = `${name}.${ext}`;

        const ref = Storage.ref(storage.instance, `${path}/${name}`);
        return $q.when(Storage.uploadBytes(ref, file)).then((res) => {
            return Storage.getDownloadURL(res.ref).then((url) => {
                return url;
            })
        })
    }

    storage.downloadBlob = (url, name) => {
        const ref = Storage.ref(storage.instance, url);
        const ext = ref.name.split('.').pop();
        name = `${name}.${ext}`;

        return $q.when(Storage.getBlob(ref)).then((blob) => {
            $utils.downloadBlob(blob, name);
        })
    }

    const db = {};
    db.instance = Firestore.getFirestore();

    db._registroBase = {
        criar: {
            criacao: { data: '_timestamp', idUsuario: '_usuario' },
            alteracao: false,
            arquivado: false
        },
        atualizar: {
            alteracao: { data: '_timestamp', idUsuario: '_usuario' }
        }
    };

    db._parsePlaceholders = (val) => {
        val = structuredClone(val);

        const parseNestedFields = (obj) => {
            if (!obj || typeof obj != 'object') {
                return
            }

            Object.keys(obj).forEach((key) => {
                if (typeof obj[key] == 'object') {
                    parseNestedFields(obj[key]);

                    return
                }

                if (typeof obj[key] != 'string') {
                    return
                }

                if (obj[key] == '_timestamp') {
                    let now = new Date();
                    obj[key] = now.getTime();
                    //obj[key] = Firestore.serverTimestamp();

                    return
                }

                if (obj[key].startsWith('_increment')) {

                    const number = parseInt(obj[key].split(':')[1]);
                    obj[key] = Firestore.increment(number);

                    return
                }

                if (obj[key] == '_usuario') {

                    obj[key] = auth.getIdToken()?.claims.idUsuario ?? null;

                    return
                }

                if (obj[key].startsWith('_union')) {

                    let idx = obj[key].indexOf(':');

                    //const element = obj[key].split(':')[1];
                    let element = obj[key].slice(idx+1);

                    if(element.startsWith('{')){
                        element = JSON.parse(element)
                        parseNestedFields(element);

                        console.log('element', element);
                    }

                    obj[key] = Firestore.arrayUnion(element);

                    return
                }

                if (obj[key].startsWith('_remove')) {

                    const element = obj[key].split(':')[1];
                    obj[key] = Firestore.arrayRemove(element);

                    return
                }
            })
        }

        parseNestedFields(val);

        return val;
    }

    db.add = (path, val) => {

        val = { ...db._registroBase.criar, ...val }
        val = db._parsePlaceholders(val);

        const ref = Firestore.collection(db.instance, path);
        return $q.when(Firestore.addDoc(ref, val)).then((snap) => {
            return {
                id: snap.id,
                data: val,
                path: path
            };
        });
    }

    db.get = (path) => {

        const ref = Firestore.doc(db.instance, path);
        return $q.when(Firestore.getDoc(ref)).then((snap) => {
            return {
                id: snap.id,
                data: snap.data(),
                path: path
            };
        });
    }

    db._filterTypes = {
        orderBy: Firestore.orderBy,
        limit: Firestore.limit,
        limitToLast: Firestore.limitToLast,
        where: Firestore.where,
        startAt: Firestore.startAt,
        startAfter: Firestore.startAfter,
        endAt: Firestore.endAt
    }

    db._parseFilters = (filters) => {
        const _parseValues = (lista) => {
            lista.forEach((item, index) => {
                if (typeof item != 'string') {
                    return
                }

                if (item == '_id') {
                    lista[index] = Firestore.documentId()
                }
            })
        }

        filters.forEach((filter, index) => {
            const key = Object.keys(filter)[0];
            if (db._filterTypes[key]) {

                _parseValues(filter[key]);

                filters[index] = db._filterTypes[key](...filter[key])
            } else {
                delete filters[key];
            }
        })
    }

    db.list = (path, filters = [], arquivado = false, order = 'criacao.data.seconds') => {
        filters = [].concat(filters);

        let ascending = false;
        if (order?.startsWith('+')) {
            order = order.substring(1);
            ascending = true;
        }

        if (!arquivado) {
            filters.push({ where: ['arquivado', '==', false] });
        }

        db._parseFilters(filters);

        const ref = Firestore.collection(db.instance, path);
        const query = Firestore.query(ref, ...filters);
        return $q.when(Firestore.getDocs(query)).then((snap) => {
            const list = [];

            snap.forEach((child) => {
                list.push({
                    id: child.id,
                    data: child.data(),
                    path: child.ref.path,
                    $snap: child
                });
            });

            if (order) {
                list.sort((a, b) =>
                    String($utils.getNestedField(b.data, order)).localeCompare?.($utils.getNestedField(a.data, order), undefined, { numeric: true })
                );
            }

            if (ascending) {
                list.reverse();
            }

            return list
        });
    }

    db.set = (path, val) => {
        val = { ...db._registroBase.criar, ...val }
        val = db._parsePlaceholders(val);

        const ref = Firestore.doc(db.instance, path);
        return $q.when(Firestore.setDoc(ref, val)).then(() => {
            return;
        });
    }

    db.update = (path, val) => {
        val = { ...db._registroBase.atualizar, ...val }
        val = db._parsePlaceholders(val);

        const ref = Firestore.doc(db.instance, path);
        return $q.when(Firestore.updateDoc(ref, val)).then(() => {
            return;
        });
    }

    db.del = (path, arquivar = true) => {
        let val = { ...db._registroBase.atualizar, arquivado: true }
        val = db._parsePlaceholders(val);

        const ref = Firestore.doc(db.instance, path);
        return $q.when(arquivar
            ? Firestore.updateDoc(ref, val)
            : Firestore.deleteDoc(ref)
        )
    }

    db.listen = (path, filters = [], callback) => {
        const list = [];

        const checkFilters = [];
        filters.forEach(filter => {
            const key = Object.keys(filter)[0];

            if (key == 'limit' || key == 'limitToLast') {
                return
            }

            checkFilters.push(filter);
        })

        checkFilters.push({ limit: [ 1 ] });

        db._parseFilters(filters);
        db._parseFilters(checkFilters);

        const ref = Firestore.collection(db.instance, path);
        const query = Firestore.query(ref, ...filters);

        const deferred = $q.defer();
        let waitingEvent = true;

        const checkQuery = Firestore.query(ref, ...checkFilters);
        $q.when(Firestore.getDocs(checkQuery)).then(snap => {
            if (snap.empty && waitingEvent) {
                waitingEvent = false;
                deferred.resolve([unsub, list]);
            }
        })

        const unsubChanges = Firestore.onSnapshot(query, snap => {
            if (waitingEvent) {
                waitingEvent = false;
                deferred.resolve([unsub, list]);
            }

            $rootScope.$applyAsync(() => {
                snap.docChanges().forEach(change => {
                    if (change.type == 'added') {
                        const addedItem = {
                            id: change.doc.id,
                            data: change.doc.data(),
                            path: path
                        };

                        list.push(addedItem);

                        callback?.('added', addedItem);
                    }

                    if (change.type == 'modified') {
                        const changedItem = list.find(item => item.id == change.doc.id);
                        if (!changedItem) {
                            return;
                        }

                        changedItem.data = change.doc.data();

                        callback?.('modified', changedItem);
                    }

                    if (change.type == 'removed') {
                        const removedIndex = list.findIndex(item => item.id == change.doc.id);
                        if (removedIndex == -1) {
                            return;
                        }

                        list[removedIndex].removed = true;

                        list.splice(removedIndex, 1);

                        callback?.('removed', list[removedIndex]);
                    }
                })
            })
        })

        const unsub = () => {
            unsubChanges();
        };

        return deferred.promise;
    }

    db.batch = (actions) => {
        const batch = Firestore.writeBatch(db.instance);

        actions.forEach(action => {
            const ref = Firestore.doc(db.instance, action.path);

            let registroBase = {};

            if (action.method == 'set') {
                registroBase = db._registroBase.criar;
            }

            if (action.method == 'update') {
                registroBase = db._registroBase.atualizar;
            }

            if (action.method == 'delete' && action.arquivar != false) {
                action.method = 'update';
                action.data =  { arquivado: true };
            }

            let data = { ...registroBase, ...action.data }
            data = db._parsePlaceholders(data);

            batch[action.method](ref, data);
        })

        return $q.when(batch.commit()).then(() => {
            return;
        });
    }

    const rt = {};
    rt.instance = Database.getDatabase();

    rt._registroBase = {
        criar: {
            criacao: { data: '_timestamp', idUsuario: '_usuario' },
            alteracao: false
        },
        atualizar: {
            alteracao: { data: '_timestamp', idUsuario: '_usuario' }
        }
    };

    rt._parsePlaceholders = (val) => {
        val = structuredClone(val);

        const parseNestedFields = (obj) => {
            if (typeof obj != 'object') {
                return
            }

            Object.keys(obj).forEach((key) => {
                if (typeof obj[key] == 'object') {
                    parseNestedFields(obj[key]);

                    return
                }

                if (typeof obj[key] != 'string') {
                    return
                }

                if (obj[key] == '_timestamp') {
                    obj[key] = Database.serverTimestamp();

                    return
                }

                if (obj[key].startsWith('_increment')) {
                    const number = parseInt(val[key].split(':')[1]);
                    val[key] = Database.increment(number);

                    return
                }

                if (obj[key] == '_usuario') {
                    obj[key] = auth.getIdToken()?.claims.idUsuario ?? false;

                    return
                }
            })
        }

        parseNestedFields(val);

        return val;
    }

    rt.add = (path, val) => {
        val = { ...rt._registroBase.criar, ...val }
        val = rt._parsePlaceholders(val);

        const ref = Database.ref(rt.instance, path);
        return $q.when(Database.push(ref, val)).then((snap) => {
            return {
                id: snap.key,
                data: val,
                path: path
            };
        });
    }

    rt.get = (path) => {
        const ref = Database.ref(rt.instance, path);
        return $q.when(Database.get(ref)).then((snap) => {
            return {
                id: snap.key,
                data: snap.val(),
                path: path
            };
        });
    }

    rt._filterTypes = {
        limitToFirst: Database.limitToFirst,
        limitToLast: Database.limitToLast,
        startAt: Database.startAt,
        startAfter: Database.startAfter,
        endAt: Database.endAt,
        endBefore: Database.endBefore,
        equalTo: Database.equalTo,

        orderByChild: Database.orderByChild,
        orderByKey: Database.orderByKey,
        orderByValue: Database.orderByValue,
    }

    rt._parseFilters = (filters) => {
        filters.forEach((filter, index) => {
            const key = Object.keys(filter)[0];
            if (rt._filterTypes[key]) {
                filters[index] = rt._filterTypes[key](...filter[key])
            } else {
                delete filters[key];
            }
        })
    }

    rt.list = (path, filters = []) => {
        rt._parseFilters(filters);

        const ref = Database.ref(rt.instance, path);
        const query = Database.query(ref, ...filters);
        return $q.when(Database.get(query)).then((snap) => {
            const list = [];

            snap.forEach((child) => {
                list.push({
                    id: child.key,
                    data: child.val(),
                    path: path
                });
            });

            return list;
        });
    }

    rt.set = (path, val) => {
        val = { ...rt._registroBase.criar, ...val }
        val = rt._parsePlaceholders(val);

        const ref = Database.ref(rt.instance, path);
        return $q.when(Database.set(ref, val)).then((snap) => {
            return;
        });
    }

    rt.update = (path, val) => {
        val = { ...rt._registroBase.atualizar, ...val }
        val = rt._parsePlaceholders(val);

        const ref = Database.ref(rt.instance, path);
        return $q.when(Database.update(ref, val)).then((snap) => {
            return;
        });
    }

    rt.del = (path, val) => {
        const ref = Database.ref(rt.instance, path);
        return $q.when(Database.remove(ref)).then(() => {
            return;
        });
    }

    rt.listen = (path, filters = [], callback) => {
        const list = [];

        const checkFilters = [];
        filters.forEach(filter => {
            const key = Object.keys(filter)[0];

            if (key == 'limitToFirst' || key == 'limitToLast') {
                return
            }

            checkFilters.push(filter);
        })

        checkFilters.push({ limitToFirst: [ 1 ] });

        rt._parseFilters(filters);
        rt._parseFilters(checkFilters);

        const ref = Database.ref(rt.instance, path);
        const query = Database.query(ref, ...filters);

        const deferred = $q.defer();
        let waitingAdd = true;

        const checkQuery = Database.query(ref, ...checkFilters);
        $q.when(Database.get(checkQuery)).then(snap => {
            if (!snap.size && waitingAdd) {
                waitingAdd = false;
                deferred.resolve([unsubAll, list]);
            }
        })

        const unsubAdd = Database.onChildAdded(query, snap => {
            $rootScope.$applyAsync(() => {
                if (waitingAdd) {
                    waitingAdd = false;
                    deferred.resolve([unsubAll, list]);
                }

                const addedItem = {
                    id: snap.key,
                    data: snap.val(),
                    path: path
                };

                list.push(addedItem);

                callback?.('added', addedItem);
            })
        })

        const unsubChanged = Database.onChildChanged(ref, snap => {
            const changedItem = list.find(item => item.id == snap.key);
            if (!changedItem) {
                return;
            }

            $rootScope.$applyAsync(() => {
                changedItem.data = snap.val();

                callback?.('changed', changedItem);
            })
        })

        const unsubRemoved = Database.onChildRemoved(ref, snap => {
            const removedIndex = list.findIndex(item => item.id == snap.key);
            if (removedIndex == -1) {
                return;
            }

            $rootScope.$applyAsync(() => {
                list[removedIndex].removed = true;
                list.splice(removedIndex, 1);

                callback?.('removed', list[removedIndex]);
            })
        })

        const unsubAll = () => {
            unsubAdd();
            unsubChanged();
            unsubRemoved();
        };

        return deferred.promise;
    }

    rt.disconnected = (path, val) => {
        const ref = Database.ref(rt.instance, path);
        const disc = Database.onDisconnect(ref);

        return $q.when(val
            ? disc.set(val)
            : disc.remove()
        ).then(() => {
            return disc.cancel
        })
    }

    const functions = {};
    functions.instance = Functions.getFunctions();

    functions.invoke = (name, payload) => {
        return $q.when(Functions.httpsCallable(functions.instance, name)(payload)).then((data) => {
            return data.data;
        });
    }

    if (config.emulator) {
        const emu = config.emulator;

        emu.auth && Auth.connectAuthEmulator(auth.instance, `http://${emu.auth.host}:${emu.auth.port}`);
        emu.functions && Functions.connectFunctionsEmulator(functions.instance, emu.functions.host, emu.functions.port);
        emu.firestore && Firestore.connectFirestoreEmulator(db.instance, emu.firestore.host, emu.firestore.port);
        emu.database && Database.connectDatabaseEmulator(rt.instance, emu.database.host, emu.database.port);
        emu.storage && Storage.connectStorageEmulator(storage.instance, emu.storage.host, emu.storage.port);
    }

    auth.instance.setPersistence(Auth.indexedDBLocalPersistence);

    //Cloud Messaging
    const messaging = getMessaging(app);

    const _checkMessagingToken=()=>{
        getToken(messaging, {vapidKey: APP_CONSTANTS.APP_CONFIG.vapIdKey}).then((currentToken) => {
            if (currentToken) {
                const savedToken = $utils.localStorageAvailable()?localStorage.getItem("fcm-token"):false;

                if(savedToken!==currentToken){
                    _saveTokenToServer(currentToken);
                };

            } else { console.log('No token available.'); }
        }).catch((err)=>{ console.log('An error occurred while retrieving token. ', err); });

    };

    const _saveTokenToServer=(token)=>{
        const idUsuario = auth.getIdToken().claims.idUsuario;

        const obj = { [`tokens.${APP_CONSTANTS.APP_CONFIG.appLabel}`]: Firestore.arrayUnion(token) };

        const ref = Firestore.doc(db.instance, `/usuarios/${idUsuario}`);
        return $q.when(Firestore.updateDoc(ref, obj)).then(() => {
            if($utils.localStorageAvailable()){ localStorage.setItem("fcm-token", token); }
            return;
        });
    };

    const _requestPermission=()=>{
        /* if('serviceWorker' in navigator){
            // Register service worker
            navigator.serviceWorker.register('service-worker.js').then(function(reg){
                console.log("SW registration succeeded."); */

                if (("Notification" in window)) {
                    console.log('Requesting permission...');

                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            _checkMessagingToken();
                            //console.log('Notification permission granted.');
                        }
                    });
                }

        /*     }).catch(function(err){
                console.error("SW registration failed with error "+err);
            });
        } */
    };
    //--End Cloud Messaging

    return {
        auth, storage, db, rt, functions
    }
}

core.provider('coreFb', function() {

    let config = {};

    this.firebaseConfig = (val) => {
        config = val;
    }

    this.$get = ['$rootScope', '$q', 'coreUtils', ($rootScope, $q, $utils) => FirebaseService($rootScope, $q, $utils, config)]
});