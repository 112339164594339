core.component('coreChatRespostasComponent', {
    templateUrl: 'components/chat/respostas.html',
    controller: 'core.chatRespostasController',
    bindings: { respostasPath: '<', mensagemAtual: '=', onSelect: '<' }
});

core.controller('core.chatRespostasController', ['$scope', 'coreToast', 'coreFb', function($scope, $toast, $fb) {

    const $ctrl = this;

    $ctrl.showForm = false;

    $ctrl.toggleForm = () => {

        $ctrl.showForm = !$ctrl.showForm;

        if (!$ctrl.showForm) {
            $ctrl.formResposta = null;
            $ctrl.formData = {};
        }
    }

    $ctrl.formResposta = null;

    $ctrl.editResposta = (resposta) => {

        $ctrl.formResposta = resposta;

        $ctrl.formData = {
            titulo: resposta.data.titulo,
            mensagem: resposta.data.mensagem,
        };

        $ctrl.toggleForm();
    }

    $ctrl.delResposta = () => {

        $ctrl.isSubmiting = true;

        $fb.db.del(`${$ctrl.respostasPath}/${$ctrl.formResposta.id}`).then(() => {
            const index = $ctrl.listaRespostas.indexOf($ctrl.formResposta);
            $ctrl.listaRespostas.splice(index, 1);

            delete $ctrl.listaTitulos[$ctrl.formResposta.data.titulo];

            $ctrl.toggleForm();
        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    }

    $ctrl.formData = {};

    $ctrl.isSubmiting = false;

    $ctrl.submitResposta = () => {

        if ($ctrl.respostaForm.$invalid) {
            return
        }

        const respostaData = { ... $ctrl.formData };

        if ($ctrl.formResposta) {
            if ($ctrl.formResposta.data.titulo != $ctrl.formData.titulo && $ctrl.listaTitulos[$ctrl.formData.titulo]) {
                return
            }

            $ctrl.isSubmiting = true;

            $fb.db.update(`${$ctrl.respostasPath}/${$ctrl.formResposta.id}`, respostaData).then(() => {
                delete $ctrl.listaTitulos[$ctrl.formResposta.data.titulo];

                $ctrl.listaTitulos[$ctrl.formData.titulo] = $ctrl.formResposta;

                Object.assign($ctrl.formResposta.data, respostaData);

                $toast.success('A resposta foi alterada.');

                $ctrl.toggleForm();
            }).finally(() => {
                $ctrl.isSubmiting = false;
            });

            return
        }

        if ($ctrl.listaTitulos[$ctrl.formData.titulo]) {
            return
        }

        $ctrl.isSubmiting = true;

        $fb.db.add($ctrl.respostasPath, respostaData).then(res => {
            $ctrl.listaRespostas.push(res);
            $ctrl.listaTitulos[res.data.titulo] = res;

            $toast.success('A resposta foi cadastrada.');

            $ctrl.toggleForm();
        }).finally(() => {
            $ctrl.isSubmiting = false;
        });
    }

    $ctrl.onInputValueChanged = () => {
        const matchResposta = $ctrl.listaTitulos[$ctrl.mensagemAtual];
        if (!matchResposta) {
            return
        }

        $ctrl.onSelect(matchResposta.data.mensagem);
    }

    $ctrl.listaRespostas = null;
    $ctrl.listaTitulos = {};

    $ctrl.$onInit = () => {

        $ctrl.deregisterOnInputValueChanged = $scope.$watch('$ctrl.mensagemAtual', $ctrl.onInputValueChanged)

        $fb.db.list($ctrl.respostasPath, undefined, undefined, '+criacao.data.seconds').then(list => {
            $ctrl.listaRespostas = list;

            for (const resposta of $ctrl.listaRespostas) {
                $ctrl.listaTitulos[resposta.data.titulo] = resposta;
            }
        })
    }
}])