core.factory('coreLanguage', ()=>{return{
    //Titles
    appName: 'Ai Doctor Tools',
    signIn: 'Crie uma conta',
    login: 'Entrar',
    loginWithLink: 'Link de Login',
    passwordRecover: 'Recuperar Senha',
    resendFirstAccess: 'Reenvio de 1º acesso',

    menu:{
        home: "Início",
        account: "Minha conta",
        contacts: "Contatos"
    },
    account:{
        myProfile: "Meus dados",
        address: "Endereço"
    },
    passRequires:{
        minChars:"8 caracteres",
        number: "1 Número",
        upperCase: "1 Letra maiúscula",
        specialChar: "1 Caractere especial"
    },

    ///Labels and Placeholders
    email: "Email",
    password: "Senha",
    name:'Nome',
    fullName: "Nome completo",
    phone: "Telefone / Whatsapp",
    taxNumber: "CPF",
    birthDate: "Data de nascimento",
    postalCode: "CEP",
    address1: "Logradouro",
    address2: "Bairro",
    address3: "Complemento",
    addressNumber: "Nº",
    city: "Cidade",
    province: "UF",
    personalData: "Dados de cadastro",
    accessData: "Dados de acesso",
    passConfirm: "Confirmar Senha",
    stage: "Etapa",
    list: "Lista",
    temperature: "Temperatura",
    specialty: "Especialidade",
    responsible: "Responsável",
    observations: "Observações",
    lastContact: "Últ. msg",
    manageContact: "Gerenciar contato",

    ///Links and Buttons
    forgotPassword: "Esqueceu a senha?",
    back: "Voltar",
    sendLink: "Enviar Link",
    sendRecoverLink: "Enviar link de recuperação",
    sendAccessEmail: "Reenviar dados de 1º acesso",
    tryAgain: "Tentar de novo",
    logout: "Sair",
    save: "Salvar",
    cancel: "Cancelar",
    register: "Cadastrar",
    add: "Adicionar",
    edit:"Editar",
    viewFilters: "Mostrar filtros",
    sendMessage: "Enviar msg",

    ///Info and Helpers
    incompatibleResource: "Este navegador não é compatível com esta funcionalidade.",
    infoLoginLink: "Você receberá uma mensagem por email contendo um link de login.",
    infoRecoverLink: "Você receberá um email com o link de redefinição de senha.",
    infoResend: "Você receberá a mensagem com os dados de acesso padrão. Caso você já tenha alterado sua senha antes utilize uma das opções ",
    sentLoginLink: "Enviamos para seu email um link para que você consiga fazer login. IMPORTANTE: Verifique na caixa de spam do seu email.",
    sentRecoverLink: "Enviamos para seu email um link para que você possa definir uma nova senha e acessar sua conta. IMPORTANTE: Verifique na caixa de spam do seu email.",
    sentAccesEmail: "Enviamos para seu email os dados de 1º acesso. IMPORTANTE: Verifique na caixa de spam do seu email.",
    successfullUpdate: "Alteração realizada!",
    agreementText: "Ao se cadastrar no sistema você está de acordo com os",
    usageTerms: "Termos de uso",

    ///Errors
    somethingWrong: "Oops, há algo de errado.",
    resourceNotFound: "O recurso solicitado não foi encontrado.",
    resourceNotEnabled: "Os recursos não estão disponíveis.",
    formGenError: "Os dados informados não são válidos. Verifique e tente novamente.",
    errNoAccount:"Ops! Você tem uma conta? Confira se o email está correto.",
    wrongPass:"Ops! Parece que algo está errado, verifique sua senha.",
    blockedAccount:"Sua conta está desativada! Por favor, entre em contato.",
    temporaryBlockedAccount:"Bloqueada por excesso de tentativas, redefina a senha ou aguarde alguns minutos.",
    emailAlreadyExists: "Email já cadastrado, faça login",

    ///Errors - required fields
    requiredEmail: "Informe seu email",
    requiredPassword: "Informe sua senha",
    requiredPassConfirm: "Obrigatório confirmar a senha",
    requiredName: "Informe o seu nome completo",
    requiredPhone: "Obrigatório informar telefone/whatsapp",
    requiredTaxNumber: "Obrigatório informar o CPF",
    requiredBirthDate: "Obrigatório informar o nascimento",
    requiredPostalCode: "O código postal informado é inválido",
    requiredAgreement: "Necessário aceitar os termos de uso para continuar",
    passwordRequirement: "A senha precisa conter",

    //Empty and placeholders
    emptyContacts: "Nenhum contato correspondente",
    searchPlaceholder: "Buscar por nome...",
    searchByPhone: "Buscar por telefone...",

    ///Mask and Formats
    maskPhone: "(99) 9999?9-9999",
    maskTaxNumber:"999.999.999-99",
    maskPostalCode: "99999-999"

}});