const contatos = angular.module('contatos', [])

contatos.config(['$stateProvider', function($stateProvider) {

    const routes = [
        {
            name: 'crmw.contatos',
            url: '/contatos',
            params: { showGlobalLoading: { dynamic: true, value: true } },
            component: 'contatos.contatosComponent'
        }
    ];

    routes.forEach($stateProvider.state);
}]);