var core = angular.module('core', ['ui.router', 'ui.validate', 'ui.mask', 'ngSanitize']);

core.factory('$exceptionHandler', ['$injector', function($injector) {
    let $toast, $state;
    return function (exception, cause) {
        $toast = $toast || $injector.get('coreToast');
        $state = $state || $injector.get('$state');

        if (exception.code == 'permission-denied' || exception.message == 'Permission denied') {
            $state.go('erroPermissao', undefined, { location: false });
            //console.error(exception, cause);
            return
        }

        $toast.fail();
        console.error(exception, cause);
    };
}]);